
@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Myster';
	src: url('../fonts/myster.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}




