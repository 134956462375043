/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.main-header__slick .slick-list {
  padding-left: 0 !important;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
      outline: none;
  }

  &.dragging {
      cursor: pointer;
      cursor: hand;
  }
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  margin-left: auto;
  margin-right: auto;

  .slick-loading & {
      visibility: hidden;
  }
}
.slick-slide {


  img {
      display: block;
  }

  &.slick-loading img {
      display: none;
  }


  &.dragging img {
      pointer-events: none;
  }

  .slick-initialized & {
      display: block;
  }

  .slick-loading & {
      visibility: hidden;
  }

  .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
  }
}

.slick-slider .slick-disabled {
  opacity: 0.3;
}

